<template>
  <common-form
      ref="form"
      url="web/iot/water/device"
      :edit="edit"
      :items="items"
      :beforeRequest="beforeRequest"
      @success="$emit('success')"></common-form>
</template>

<script>

export default {
  data() {
    return {
      edit: false,
      modelOptions: [],
      vendorOptions: [],
      platformOptions: [
        {
          name: "机智云",
          value: "GIZWITS"
        },
        {
          name: "MQTT DTU",
          value: "EMQTT"
        }
      ]
    }
  },
  computed: {
    items() {
      let arr = [
        {
          type: "input",
          name: "名称",
          key: "nickname",
          required: true
        },
        {
          type: "input",
          name: "MAC/IMEI",
          key: "mac",
          required: true,
          disable: this.edit
        },
        {
          type: "select",
          name: "设备型号",
          key: "modelId",
          options: this.modelOptions,
          required: true
        },
        {
          type: "location",
          name: "地址",
          key: "location",
        },
        {
          type: "select",
          name: "设备平台",
          key: "platform",
          options: this.platformOptions,
          required: true,
          disable: this.edit
        },
      ]
      if (!this.edit) {
        arr.push({
          type: "select",
          name: "授权账号",
          key: "vendorId",
          options: this.vendorOptions
        })
      }
      return arr
    }
  },
  methods: {
    show(model = {}) {
      this.edit = model.id > 0
      if (this.edit) {
        model.location = {
          address: model.address,
          latitude: model.latitude,
          longitude: model.longitude
        }
      }
      this.$refs.form.show(model)

      this.$get("web/iot/water/model/all").then(suc => {
        let options = []
        suc.data.forEach(item => {
          options.push({
            name: item.model,
            value: item.id
          })
        })
        this.modelOptions = options
      })

      this.$get("vendor", {pageSize: 200}).then(suc => {
        let options = []
        suc.data.rows.forEach(item => {
          options.push({
            name: item.name,
            value: item.id
          })
        })
        this.vendorOptions = options
      })

    },

    beforeRequest(model) {
      return {
        id: model.id,
        nickname: model.nickname,
        name: model.name,
        platform: model.platform,
        mac: model.mac,
        modelId: model.modelId,
        vendorId: model.vendorId,
        ...model.location
      }
    }
  }

}
</script>
<style lang="less" scoped>
.block {
  padding: 5px;
  background: #FFF;
  border-radius: 5px;
  border: 1px solid #F0F0F0;

  .item-container {
    width: 25%;
    float: left;
    padding: 5px;
  }

  .item {
    border-radius: 5px;
    background: #F8F8F8;
    padding: 5px 15px;
  }

}
</style>
