<template>
  <a-modal v-model="visible" title="MQTT连接信息" :destroyOnClose="true"
           okText="确定">
    <a-alert message="请将以下信息提供给模块厂商，配置到模块中" type="info" />
    <a-descriptions  :column="1" :bordered="true" size="small" style="margin-top: 12px">
      <a-descriptions-item label="ClientId">{{ device.mac }}</a-descriptions-item>
      <a-descriptions-item label="服务器IP">47.104.76.230</a-descriptions-item>
      <a-descriptions-item label="端口">1883</a-descriptions-item>
      <a-descriptions-item label="uasename">{{device.mac}}</a-descriptions-item>
      <a-descriptions-item label="password">{{ device.deviceSecret }}</a-descriptions-item>
      <a-descriptions-item label="订阅Topic">sub/{{device.mac}}</a-descriptions-item>
      <a-descriptions-item label="发布Topic">pub/{{device.mac}}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
export default {
  data(){
    return {
      visible:false,
      device:{}
    }
  },
  methods:{
    show(device){
      this.device=device
      this.visible=true
    }
  }
}
</script>

<style scoped>

</style>
